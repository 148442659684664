import * as button from "../styles/buttons.module.scss"
import { Link } from "gatsby"
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "../styles/fitnesservices.module.scss"

// Ez a komponens tartalmazza a szolgáltatásokat, amely a főoldalon és a szolgáltatások menüpont alatt jelenik meg

const FitnessServices = () => {
  return (
    <section id={styles.serviceBar}>
      <div className={styles.serviceBarContainer}>
        {/* 1. szolgáltatás */}
        <div className={styles.service}>
          <StaticImage
            className={styles.images}
            src="../images/personal-small.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Gym"
          />

          <div className={styles.details}>
            <h2>Személyi edzés</h2>
            <p>60 perc</p>
            <div className={styles.buttonContainer}>
              <Link
                className={`${button.buttons} ${button.buttonLink2}`}
                to="/szemelyi-edzesek/"
              >
                Tudj meg többet...
              </Link>
            </div>
          </div>
        </div>

        {/* 2. szolgáltatás */}
        <div className={styles.service}>
          <StaticImage
            className={styles.images}
            src="../images/group-small.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Gym"
          />

          <div className={styles.details}>
            <h2>Csoportos edzés</h2>
            <p>60 perc</p>
            <div className={styles.buttonContainer}>
              <Link
                className={`${button.buttons} ${button.buttonLink2}`}
                to="/csoportos-fitnesz/"
              >
                Tudj meg többet...
              </Link>
            </div>
          </div>
        </div>

        {/* 3. szolgáltatás */}
        <div className={styles.service}>
          <StaticImage
            className={styles.images}
            src="../images/online-small.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Gym"
          />
          <div className={styles.details}>
            <h2>Online edzés</h2>
            <p>60 perc</p>
            <div className={styles.buttonContainer}>
              <Link
                className={`${button.buttons} ${button.buttonLink2}`}
                to="/online-fitnesz/"
              >
                Tudj meg többet...
              </Link>
            </div>
          </div>
        </div>
        {/* </div> */}

        {/* <div id={styles.secondLine}> */}
        <div className={styles.service}>
          <StaticImage
            className={styles.images}
            src="../images/body-small.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Gym"
          />
          <div className={styles.details}>
            <h2>Testösszetétel mérés</h2>
            <p>15 perc</p>
            <div className={styles.buttonContainer}>
              <Link
                className={`${button.buttons} ${button.buttonLink2}`}
                to="/bodycomposition/"
              >
                Tudj meg többet...
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.service}>
          <StaticImage
            className={styles.images}
            src="../images/kids-small.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Gym"
          />
          <div className={styles.details}>
            <h2>Gyermek fitnesz</h2>
            <p>45 perc</p>
            <div className={styles.buttonContainer}>
              <Link
                className={`${button.buttons} ${button.buttonLink2}`}
                to="/gyerek-fitnesz/"
              >
                Tudj meg többet...
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.service}>
          <StaticImage
            className={styles.images}
            src="../images/camp-small.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Gym"
          />
          <div className={styles.details}>
            <h2>Fitnesz tábor</h2>
            <p>3 nap</p>
            <div className={styles.buttonContainer}>
              <Link
                className={`${button.buttons} ${button.buttonLink2}`}
                to="/fitnesz-tabor/"
              >
                Tudj meg többet...
              </Link>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </section>
  )
}

export default FitnessServices
